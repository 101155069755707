import {SDK_CONTEXT} from './consts'
import {AppData} from '@wix/editor-platform-sdk-types'
export const isExtensionContext = (appData: AppData) =>
  appData.context === SDK_CONTEXT.EXTENSION

type AppDataContextProps = Pick<AppData, 'appDefinitionId' | 'applicationId'>
export function resolveOption<Prop extends keyof AppDataContextProps>(
  appData: AppData,
  options: any,
  propertyToResolve: Prop,
  {isRequired}: {isRequired: boolean} = {isRequired: false},
): AppDataContextProps[Prop] {
  const option = isExtensionContext(appData)
    ? options?.[propertyToResolve]
    : appData?.[propertyToResolve]

  if (isRequired && option === undefined) {
    throw new Error(`option ${propertyToResolve} is required`)
  }

  return option
}

/**@hidden**/
export interface AppDataTokenOptions<OptionsType, R> {
  (appData: AppData, token: string, options: OptionsType): R
}

/**@hidden**/
export function deprecatedMethod(path) {
  console.warn(
    `editorSDK.${path} has been deprecated - please do not use it anymore. For more information, see the API reference.`,
  )
}
